
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator'
import { State, Action } from 'vuex-class'
/* HELPERS */
import { eventDate, eventDateTime } from '@/helpers/date'
import { decryptQueryParams } from 'query-string-hash'

@Component
export default class TicketQR extends Vue {
  public eventName = null
  public eventLocation = null
  public qrData = null
  public productName = null
  public productInitialDate = null
  public productFinishDate = null
  public assistantFullName = null
  public productDescription = null
  public assistantDNI = null
  public assistantGender = null

  get eventDateInit(): string {
    return eventDate(this.productInitialDate)
  }
  get eventDateEnd(): string {
    return eventDate(this.productFinishDate)
  }
  get eventDateTimeInit(): string {
    return eventDateTime(this.productInitialDate)
  }
  get eventDateTimeEnd(): string {
    return eventDateTime(this.productFinishDate)
  }
  get dateInitEvent(): string {
    return eventDateTime(this.productInitialDate)
  }

  mounted() {
    const data = decryptQueryParams(this.$route.params.data)

    this.eventName = data.eventName
    this.eventLocation = data.eventLocation
    this.qrData = data.qrData
    this.productName = data.productName
    this.productInitialDate = data.productInitialDate
    this.productFinishDate = data.productFinishDate
    this.assistantFullName = data.assistantFullName
    this.productDescription = data.productDescription
    this.assistantDNI = data.assistantDNI

    if (data.assistantGender == 'male') {
      this.assistantGender = 'Masculino'
    } else if (data.assistantGender == 'female') {
      this.assistantGender = 'Femenino'
    } else {
      this.assistantGender = 'Otro'
    }
  }

  protected capture() {
    const captureElement = document.querySelector('#capture')
    this.$html2canvas(captureElement)
      .then((canvas: any) => {
        canvas.style.display = 'none'
        document.body.appendChild(canvas)
        return canvas
      })
      .then((canvas: any) => {
        const image = canvas
          .toDataURL('image/png')
          .replace('image/png', 'image/octet-stream')
        const a = document.createElement('a')
        a.setAttribute('download', 'TQR - ' + this.productName + '.png')
        a.setAttribute('href', image)
        a.click()
        canvas.remove()
      })
  }
}
